<template>
    <section>
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="w-full flex gap-2 items-center">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listado de Bodegas</p>
          <Button @click="displayModalBodega = true, idBodega = null" class="ml-auto rounded-md" label="Crear Bodega" />
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <!-- filtro por codigos de articulo -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cod_bod">Código de la bodega</label>
            <InputText class="w-full" id="cod_bod" @keyup.enter="listarBodegas" v-model="filtros.WhsCode" />
          </div>
          <!-- filtro por descripcion -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="descripcion">Nombre</label>
            <InputText class="w-full" id="descripcion" v-model="filtros.WhsName"/>
          </div>
          <!-- filtro por bodega -->
          <div class="flex gap-2">
            <Button @click="listarBodegas" class="mt-5 mb-1.5 rounded-md" label="Buscar" />
            <Button @click="limpiarFiltros" class="mt-5 mb-1.5 rounded-md p-button-outlined p-button-secondary" label="Limpiar" />
          </div>
        </div>
        <div class="w-full my-2">
          <DataTable class="p-datatable-sm text-xs" :value="bodegas.rows" responsiveLayout="scroll">
            <Column header="Codigo">
              <template #body="{data}">
                <div class="text-center pt-3">
                  <p>{{ data.WhsCode }}</p>
                  <div class="flex gap-x-4 hover:text-blue-600 text-white transition ease-in-out duration-500">
                    <button class="underline italic" @click='EditMD(data.WhsCode)'>Editar</button>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="WhsName" header="Nombre"></Column>
            <Column field="StreetNo" header="Dirección"></Column>
            <Column field="U_PHR_CentroCosto" header="Centro de costo"></Column>
            <Column field="U_PHR_Dpto" header="Cod Dpto"></Column>
            <Column field="U_PHR_DaysAverage" header="Dias promedio"></Column>
            <Column field="U_PHR_DaysMin" header="Dias min"></Column>
            <Column field="U_PHR_DaysMax" header="Dias max"></Column>

            <Column header="Acción">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" >
                  <Button
                      @click='DeleteBod(data.WhsCode)'
                      severity="danger"
                      v-tooltip="'Eliminar'"
                      class='p-button-rounded p-button-icon-only mx-2'
                  >
                    <i class="pi pi-trash text-white"></i>
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(bodegas.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
      <Dialog :header="`${idBodega ? 'Actualizar' : 'Crear'} Bodega`" v-model:visible="displayModalBodega" :breakpoints="{'960px': '95vw'}" :style="{width: '60vw'}" :modal="true">
        <div class="grid gap-4 bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xxl:grid-cols-5">
          <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
            <crearBodega @crearBodega="displayModalBodega = false, listarBodegas()" :idBod="idBodega" />
          </div>
        </div>

        <!-- <template #footer>
          <Button label="Cerrar" icon="pi pi-times" @click="closeModalSupports" class="p-button-xs p-button-danger"/>
          <Button label="Guardar" icon="pi pi-check" @click="submitSupport(numberStage)" class="p-button-xs p-button-success" autofocus />
        </template> -->
      </Dialog>
      <Toast />
    </section>
</template>
<script setup>
  import dayjs from 'dayjs'
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  import BodegasService from '../../../../../services/bodegas.service'
  import { onMounted, ref, computed } from 'vue'
  import crearBodega from './crear.vue'
    // name: 'ListadoOrdenesVenta'
    // Servicios
    const toast = useToast()
    const _BodegasService = ref(new BodegasService())
    // Referencias
    const bodegas = ref([])
    const BodSeleccionada = ref()
    const limit = ref(10)
    const offset = ref(0)
    const filtros = ref({
      ItemName: null,
      CardCode: null
    })
    const idBodega = ref(null)
    const displayModalBodega = ref(false)
    const params = computed(() => {
      return {
        limit: limit.value,
        offset: offset.value,
        ...filtros.value
      }
    })
    // Metodos
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      listarBodegas(params.value)
    }
    const listarBodegas = () => {
      const object = {
        ...params.value,
        limit: limit.value,
        offset: offset.value
      }
      _BodegasService.value.paginate(object).then(({ data }) => {
        bodegas.value = data
      })
    }
    const EditMD = (id) => {
      displayModalBodega.value = true
      idBodega.value = id
    }
    const DeleteBod = (id) => {
      Swal.fire({
          title: 'Esta seguro de eliminar esta bodega?',
          showDenyButton: true,
          denyButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar'
        }).then((result) => {
          if (result.isConfirmed) {
            _BodegasService.value.deleteBodega(id).then(({ data }) => {
              if (data.error) {
                console.error('Error al eliminar la bodega, por favor intente nuevamente')
              } else {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Bodega eliminada exitosamente', life: 3000 })
              }
              listarBodegas()
            })
          } else if (result.isDenied) {
            Swal.fire('Bodega no eliminado', '', 'info')
          }
        })
    }
    const limpiarFiltros = () => {
      filtros.value = {
        CardCode: null,
        fecha_inicio: dayjs().format('YYYY-MM-DD'),
        fecha_fin: dayjs().format('YYYY-MM-DD'),
        Id: null,
        U_PHR_Bodega: null,
        U_ACS_TpoIdentf: null,
        U_PHR_NumDcto: null,
        U_PHR_ModContrato: null
      }
      BodSeleccionada.value = ''
      listarBodegas()
    }
    onMounted(() => {
      listarBodegas()
    })
</script>
<style>
  .p-dropdown-label{
    font-size: 0.75rem !important
  }
  .tipoId .p-dropdown-trigger {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
</style>
