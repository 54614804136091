import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_CONFIG_OV

export default class BodegasService {
  listarBodegas (name) {
    return http.get(`${baseUrl}/bodegas/search/${name}`, {
      headers: {
        loading: true
      }
    })
  }

  listarBodegasTodas () {
    return http.get(`${baseUrl}/bodegas/search`, {
      headers: {
        loading: true
      }
    })
  }

  getBodega (idBod) {
    return http.get(`${baseUrl}/bodegas/find/${idBod}`, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/bodegas/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getDptos (nombre = '') {
    return http.get(`${baseUrl}/localizacion/search/${nombre}`, {
      headers: {
        loading: true
      }
    })
  }

  bodegasUsuario (id, params) {
    return http.get(`${baseUrl}/bodegas-usuarios/bodegas-by-user/${id}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  asignarBodegasAUsuario (body) {
    return http.post(`${baseUrl}/bodegas-usuarios/asignar-bodegas`, body, {
      headers: {
        loading: true
      }
    })
  }

  crearBodega (body) {
    return http.post(`${baseUrl}/bodegas`, body, {
      headers: {
        loading: true
      }
    })
  }

  editarBodega (body) {
    return http.put(`${baseUrl}/bodegas/${body.WhsCode}`, body, {
      headers: {
        loading: true
      }
    })
  }

  deleteBodega (id) {
    return http.delete(`${baseUrl}/bodegas/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
